.Programs{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding: 0 2rem;
}
.Programs-headers{
    display: flex;
    gap:5rem;
    font-weight: bold;
    font-size:3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.catagory{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap:1rem;
    color:white;
    justify-content: space-around;
}
.programs-catagories{
    display: flex;
    gap:1rem;
}
.catagory>:nth-child(1){
   width: 2rem;
   height:2rem;
   fill:white; 
}
.catagory>:nth-child(2){
    font-size: 1rem;
    font-weight:bolder;
}
.catagory>:nth-child(3){
  font-size: 0.9rem;
  line-height:25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap:2rem;
}
.join-now>img{
    width: 1rem;
}
.catagory:hover{
 background: var(--planCard);
 cursor: pointer;
}
/* .partners{
display: flex;
gap:1rem;
}
.partners>img{
  width: 2.5rem;  
} */
@media screen  and (max-width:768){
 .Programs-headers{
    flex-direction: column;
    gap:1rem;
    font-size:x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
 }
 .programs-catagories{
  flex-direction: column;
 }
}
