.header{
    display: flex;
    justify-content: space-around;
}
.logo{
    width:10rem;
    height: 3rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap:20px;
    color:white;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex:3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}

.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
    font-weight: bolder;
}
.hero-image{
position:absolute;
top:10rem;
right:8rem;
width: 23rem;
}
.hero-image-back{
position: absolute;
top: 4rem;
right: 20rem;
z-index: -1;
}

@media screen and(max-width:768px){
   .header>:nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
   } 
   .header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
   }
}